.authForm {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bpLogo {
    margin-top: 105px;
  }

  .loginVector {
    display: flex;
    align-self: flex-start;
    margin-top: auto;
  }

  .form {
    width: 364px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      margin-bottom: 30px;
    }

    .formGroup {
      width: 100%;
      label {
        font-size: 14px;
      }
    }

    .error {
      font-size: 14px;
      text-align: left;
      padding: 12px 16px;
    }

    .submitBtn {
      :global(.spinner-border) {
        margin-right: 5px;
      }
    }
  }
}

.container {
  align-items: center;
  background-color: #f1f1f1;
  display: flex;
  flex: 1;

  .login {
    background-color: #fdfdfd;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    width: 444px;
    min-height: 568px;

    .form {
      margin-top: 30px;

      .formInputContainer {
        width: 100%;

        .formInput {
          border: 1px solid #e0e0e0;
          border-radius: 2px;
          color: #000000;
          font-size: 14px;
        }

        .placeholder {
          display: none;
        }
      }

      .submitBtn {
        background-color: #439b34;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        padding: 9px 14px;
        width: 100%;

        &:active,
        &:hover,
        &:focus {
          background-color: #439b34;
          border: none;
          box-shadow: none;
        }
      }
    }

    .errorMessage {
      color: #dc3545;
      font-size: 12px;
      margin-top: 20px;
      width: 368px;
      text-align: center;
    }

    .support {
      font-size: 12px;
      font-weight: 600;
      margin-top: 23px;
    }
  }
}

.floatLabel {
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    width: 100%;
    height: 48px;
    padding: 15px 100px 15px 22px;
    outline: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fdfdfd;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;

    &:focus {
      box-shadow: none;
      background-color: #fdfdfd;
    }
  }

  label {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;

    padding: 0 12px;
    color: #bdbdbd;
    pointer-events: none;
    position: absolute;
    transform: translate(10px, 15px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;

    &:before {
      content: "BP email address";
    }

    &.active {
      transform: translate(10px, -10px) scale(1);
      background-color: #fdfdfd;

      &:before {
        content: "BP email";
      }
    }
  }

  &:focus-within label {
    transform: translate(10px, -10px) scale(1);
    background-color: #fdfdfd;

    &:before {
      content: "BP email";
    }
  }
}
