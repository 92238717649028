@import "~typeface-open-sans/index";
@import "~typeface-roboto/index";
@import "~bootstrap/scss/bootstrap";

/**
 * Please use styled-components as much as possible. Only base classes should be defined here!
 **/

html,
body,
#root {
  height: 100%;
  margin: 0;
}

html {
  font-size: 16px; // this value = 1rem
}

body {
  font-family: "Open Sans", var(--font-family-sans-serif);
}

// TODO Move to DataGrid component.
.rdg {
  --border-color: #c4c4c4 !important;

  .hasComments {
    &::before {
      background-color: #ea6007;
      border-radius: 50%;
      content: "";
      height: 0.375rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 0.375rem;
    }
  }

  .hasError, .hasWarning {
    &::after {
      background-color: #ff0000;
      bottom: -0.375rem;
      content: "";
      height: 12px;
      position: absolute;
      right: -0.375rem;
      transform: rotate(45deg);
      transition: all 0.15s ease-in-out;
      width: 12px;
    }

    &:hover::after {
      height: 1rem;
      width: 1rem;
      bottom: -0.5rem;
      right: -0.5rem;
    }
  }

  .hasWarning {
    &::after {
      background-color:#cea834;
    }
  }

  .selected {
    background-color: #e5effa;

    &.selectedTop {
      border-top: 1px solid #66afe9;
    }

    &.selectedBottom {
      border-bottom: 1px solid #66afe9;
    }

    &.selectedLeft {
      border-left: 1px solid #66afe9;
    }

    &.selectedRight {
      border-right: 1px solid #66afe9;
    }
  }

  .rdg-row {
    &:hover .cell {
      &-input {
        background-color: darken(#f8e2cd, 10%);

        &.selected {
          background-color: darken(rgb(231, 199, 171), 10%);
        }
      }

      &-given {
        background-color: darken(#cbf0c5, 10%);

        &.selected {
          background-color: darken(rgb(156, 222, 156), 10%);
        }
      }

      &-readonly {
        background-color: darken(#f8f8f8, 10%);

        &.selected {
          background-color: darken(rgb(227, 232, 237), 10%);
        }
      }

      &-yellow {
        background-color: darken(#F8F9D7, 10%);

        &.selected {
          background-color: darken(rgb(239, 244, 233), 10%);
        }
      }

      &-gray {
        background-color: darken(#d3d3d3, 10%);

        &.selected {
          background-color: darken(rgb(220, 225, 231), 10%);
        }
      }

      &-disallowed {
        background-image: repeating-linear-gradient(
          45deg,
          transparent,
          transparent 8.5px,
          rgba(0, 0, 0, 0.10) 8.5px,
          rgba(0, 0, 0, 0.10) 17px
        );
      }
    }
  }

  .cell {
    &-formulations-total-header {
      background-color: #e3e3e3;
      border-color: #e3e3e3;
    }

    &-input {
      background-color: #f8e2cd;
      border-color: #d6c0ac;

      &.selected {
        background-color: rgb(231, 199, 171);
      }
    }

    &-given {
      background-color: #cbf0c5;
      border-color: #a9cea3;

      &.selected {
        background-color: rgb(156, 222, 156);
      }
    }

    &-readonly {
      background-color: #f8f8f8;
      border-color: #d2d2d2;

      &.selected {
        background-color: rgb(227, 232, 237);
      }
    }

    &-gray {
      background-color: #d3d3d3;
      border-color: #c3c3c3;

      &.selected {
        background-color: rgb(220, 225, 231);
      }
    }

    &-yellow {
      background-color: #F8F9D7;
      border-color: rgb(239, 240, 217);

      &.selected {
        background-color: rgb(239, 244, 233);
      }
    }

    &-link-child {
      color: rgba(0, 0, 0, 0.45);
    }

    &-subheader {
      text-align: center;
      text-transform: uppercase;
      opacity: 0.6;
    }

    &-numeric {
      text-align: right;
    }

    &-disallowed {
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 8.5px,
        rgba(0, 0, 0, 0.10) 8.5px,
        rgba(0, 0, 0, 0.10) 17px
      );
    }
  }
}

.react-select__control {
  .react-select__value-container {
    flex-wrap: nowrap;
  }

  .react-select__menu-portal {
    z-index: 1000;
  }

  .react-select__indicator {
    padding: 4px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}
